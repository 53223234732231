import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { DownloadComponent } from './download/download.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [

  {
    path: 'contact',
    component: ContactComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },

  {
    path: 'share/:customer/:file',
    component: DownloadComponent,
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
