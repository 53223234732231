<!-- Page Content -->
<div class="container-fluid">
    <div class="row justify-content-around">
        <h1 class="mt-4 text-center">Develop your business <br /> <small>with <br /></small> Good ITeas!</h1>
    </div>

    <!-- <p>The logo in the navbar is now a default Bootstrap feature in Bootstrap 4! Make sure to set the width and height of the logo within the HTML or with CSS. For best results, use an SVG image as your logo.</p> -->

    <div class="row justify-content-around mt-5">
        <div class="card col-lg-3 mt-3" [routerLink]="'contact'">
            <!-- <img src="..." class="card-img-top" alt="..."> -->


            <div class="card-body">
                <div class="c-icon">
                    <h1 class="text-center mt-4"><i class="fa fa-desktop"></i></h1>
                </div>
                <div class="c-title">
                    <h4 class="text-center ">APLIKACJE WEBOWE</h4>
                </div>
                <div class="c-description">
                    <div class="text-justify"><small>
                            <ul>
                                <li>webowe narzędzia dla biznesu</li>
                                <li>strony - wizytówki</li>
                                <li>gry i aplikacje</li>
                            </ul>
                        </small></div>
                </div>
            </div>
        </div>

        <div class="card col-lg-3 mt-3" [routerLink]="'contact'">
            <!-- <img src="..." class="card-img-top" alt="..."> -->
            <div class="c-icon">
                <h1 class="text-center mt-4"><i class="fa fa-cogs" aria-hidden="true"> </i></h1>
            </div>
            <div class="card-body">

                <div class="c-title">
                    <h4 class="text-center">AUTOMATYZACJA PROCESU BIZNESOWEGO</h4>
                </div>
                <div class="c-description">
                    <div class="text-justify"><small>
                            <ul>
                                <li>automatyzacja arkuszy kalkulacyjnych</li>
                                <li>silniki BPMN</li>
                                <li>integracja isniejących systemów</li>
                            </ul>
                        </small></div>
                </div>
            </div>
        </div>

        <div class="card col-lg-3 mt-3" [routerLink]="'contact'">
            <!-- <img src="..." class="card-img-top" alt="..."> -->
            <div class="c-icon">
                <h1 class="text-center mt-4"><i class="fa fa-exchange" aria-hidden="true"> </i></h1>
            </div>
            <div class="card-body">
                <div class="c-title">
                    <h4 class="text-center">INTEGRACJA DANYCH</h4>

                </div>
                <div class="c-description">
                    <div class="text-justify"><small>
                            <ul>
                                <li>migracja dużych kolekcji danych</li>
                                <li>dedykowane interfejsy wymiany i wzobogacania informacji</li>
                                <li>deduplikacja danych</li>
                                <!-- <li>REST Api</li> -->
                            </ul>
                        </small></div>
                </div>
            </div>
        </div>

    </div>


</div>
<!-- /.container -->