import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  customer : string = "";
  file : string = "";

  constructor(
    private activatedRoute : ActivatedRoute
  ) { }

  ngOnInit(): void {
    let params =  this.activatedRoute.snapshot.params;
    console.log(    this.activatedRoute.snapshot.params)
    this.customer = params.customer;
    this.file = params.file
  }

}
