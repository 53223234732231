<div class="container-fluid">
    <div class="row justify-content-center vetrically-aligned" style="height: 30vh;">
        <div class="col-md-3 col-6">
            <a href="#"> <img class="img-fluid w-100" src="assets/img/logo_kwadrat.png" alt=""></a>
        </div>
    </div>

<div  style="height: 50vh;" class="vetrically-aligned">
<div class="container-fluid">
    <div class="row justify-content-center">
        <label class="col-6 text-right">Email : </label>
        <div class="col-6"><a href="mailto:kontakt@good-iteas.pl">kontak@good-iteas.pl</a></div>
    </div>
    <br/>

    <div class="row justify-content-center mt-3">
        <label class="col-6 text-right">Telefon : </label>
        <div class="col-6"><a href="callto:+48607054662">(+48) 607-054-662</a></div>
    </div>

    <div class="row justify-content-center mt-3">
        <label class="col-6 text-right">Addres : </label>
        <div class="col-6">ul. Bema 29/34 <br /> 63-200 Jarocin </div>
    </div>
</div>
</div>

</div>