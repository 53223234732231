<nav class="navbar navbar-expand-lg navbar-dark static-top" style="background-color: #11658c">
    <div class="container-fluid">
 
                <a class="navbar-brand mr-0" href="#">
                    <img src="assets/img/logo_web.png" alt="">
                </a>




        <button class="navbar-toggler ml-0" type="button" data-toggle="collapse" data-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse " id="navbarResponsive">
            <ul class="navbar-nav ml-auto ">
                <!-- <li class="nav-item active">
          <a class="nav-link" href="#">Home
                <span class="sr-only">(current)</span>
              </a>
        </li> -->
                <li class="nav-item mr-5">
                    <a class="nav-link" href="#">OFERTA</a>
                </li>
                <li class="nav-item mr-5">
                    <a class="nav-link" [routerLink]="'contact'">KONTAKT</a>
                </li>
            </ul>
        </div>
    </div>
</nav>